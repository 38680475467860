<template>
    <div class="container py-2">
        <h1 class="fs-3 my-3">Consultation des contrôles réalisés par  <strong >questionnaires</strong></h1>
        <div class="card my-2">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-md-6 col-lg-4 col-12">
                        <img src="../assets/consultation.jpg" class="img-fluid" alt="consultation de contrôles" />
                    </div>
                    <div class="col display-6 ">
                        <p class="fs-4 text-center">Sélectionnez un  <strong >questionnaire</strong>  dans la liste de gauche pour afficher l'ensemble des contrôles correspondants.</p>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>